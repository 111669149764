import React, { useState, useEffect, useRef } from 'react'
import { Link } from 'gatsby'
import styled, { css } from 'styled-components'
import { motion, transform, useTransform } from 'framer-motion'
import { find, isEqual } from 'lodash'
import { useWindowScroll, useWindowSize, useMount } from 'react-use'
import Lottie from '../../utils/react-lottie-player'
import { useInView } from 'react-intersection-observer'
import { useScrollDirection } from '../../utils/scroll-direction'

import { Mark } from '../../components'
import { padding, colours, type, maxWidth } from '../../styles/global'
import { defaultEase } from '../../utils/anim'
import { parseMark } from '../../components/Mark/Mark'
import { media, useBreakpoint } from '../../styles/utils'

import animationDesktop from '../../assets/anim/home-intro-text-desktop.json'
import animationMobile from '../../assets/anim/home-intro-text-mobile.json'

const HomeIntro = (props) => {
    const { data } = props;
    const [animate, setAnimate] = useState(false)
    const [direction, setDirection] = useState(1)
    const [lottie, setLottie] = useState(false)
    const scrollDirection = useScrollDirection()
    const { y } = useWindowScroll()
    const { height, width } = useWindowSize()
    const isPhone = useBreakpoint('phone')

    const inViewOptions = {
        triggerOnce: true,
        threshold: 0.5,
    }

    const [refText, textInView] = useInView(inViewOptions)

    useEffect(() => {
        if (textInView !== animate) {
            setAnimate(textInView)
            lottie.play()
        }
    }, [textInView, lottie])

    // useEffect(() => {
    //     if (!textInView) return
    //     const wrapper = document.getElementById('introWrapper')
    //     const start = wrapper.offsetTop;
    //     const end = wrapper.offsetTop + wrapper.clientHeight + 400; 

	// 	if (scrollDirection === 'down' && direction !== 1) {
	// 		setDirection(1)
    //     } 
        
    //     if (scrollDirection === 'up' && direction !== -1) {
    //         console.log((y + height), end, (y + height) < end)
    //         if ((y + height) < end) {
    //             setDirection(-1)   
    //             lottie.play() 
    //         }
    //     } 
    // }, [textInView, scrollDirection, y, width, height])
    
    const renderAnimation = () => {
        return (
            <AnimationWrapper
                ref={refText}
            >
                <Lottie 
                    getLottieInstance={setLottie}
                    animationData={isPhone ? animationMobile : animationDesktop}
                    direction={direction}
                    loop={false}
                    speed={direction == 1 ? 1 : 2}
                />
            </AnimationWrapper>
        )
    }

    return (
		<Wrapper
            id={'introWrapper'}
        >
            {renderAnimation()}
        </Wrapper>
	)
}

// Layout

const Wrapper = styled.div`
    box-sizing: border-box;
    position: relative;
    width: 100%;
    max-width: ${maxWidth}px;

    display: flex;
    flex-direction: column;

    ${padding}

    ${media.phone`
        max-width: none;
        
    `}
`

// Animation Wrapper

const AnimationWrapper = styled.div`
    display: flex;
    flex-flow: row wrap;
    align-items: center;

    margin-top: 215px;

    ${media.phone`
        margin-top: 112px;    
    `}
`

export default HomeIntro